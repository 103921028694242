import {
  T,
  always,
  cond,
  compose,
  curry,
  get,
  getOr,
  isEmpty,
  isString,
  map,
  replace,
  startsWith,
} from 'lodash/fp'
import qs from 'querystring'

// @private
// Returns path without leading and/or trailing '/'.
const _sanitizePath = replace(/(^\/|\/$)/g, '')

// Modular scale
export const ms = (v = 0, r = Math.sqrt(2)) =>
  Math.round(10 * Math.pow(r, v)) / 10

// Space separated values
export const ssv = f => (...v) => v.map(f).join(' ')

// Returns true if the URL is internal, false otherwise.
export const isInternal = s => isString(s) && /^\/(?!\/)/.test(s)

// Returns true if window exists, false otherwise. Helpful to determine if
// rendering on the server (i.e. without window).
export const windowExists = () => typeof window !== 'undefined'

// Returns a URL with the global URL prepended to the provided path.
export const shopifyBuildUrl = path =>
  process.env.GATSBY_SHOPIFY_BUILD
    ? `https://kalapawaimarket.com${path}`
    : path

// Returns "right" if type ends with "- Right", "left" otherwise. Used to
// determine styling for PageLayoutDestinationBar.
export const deriveTypeSide = t => (t.match(/- Right$/) ? 'right' : 'left')

// Returns true if type ends with "- Alt", false otherwise. Used to determine
// styling for PageLayoutDestinationBar.
export const deriveTypeAlt = t => t.match(/- Alt$/)

// Returns the value of a given key in the location search query.
export const getLocationQuery = (location, key = '?query', defaultValue = '') =>
  getOr(defaultValue, key, qs.parse(location.search))

// Lodash's get function, but if the value is an empty string, it returns the
// default value instead.
export const getUnlessEmptyString = (obj, key, defaultValue = undefined) => {
  const result = getOr(defaultValue, key, obj)
  return result === '' ? defaultValue : result
}

// Lodash's get function, but specialized for Prismic Rich Text fields. Gets
// the text value of the field and returns the HTML value if it is not empty.
export const getRichText = compose(
  cond([
    [
      compose(
        isEmpty,
        get('text')
      ),
      always(undefined),
    ],
    [T, get('html')],
  ]),
  get
)

// Returns an array of nodes from the connection data provided as a result of a
// GraphQL query.
export const nodes = compose(
  map('node'),
  get('edges')
)

// Returns true if rawPath is a child of rawCurrentPath, false otherwise.
export const isPathActive = curry((location, path) => {
  const sanitizedPath = _sanitizePath(path)
  const sanitizedLocationPath = compose(
    _sanitizePath,
    get('pathname')
  )(location)

  if (isEmpty(sanitizedPath) || isEmpty(sanitizedLocationPath)) return false

  return startsWith(sanitizedLocationPath, sanitizedPath)
})

// Posts a form to Netlify with the provided form name and values. Returns true
// if successful, false otherwise.
export const postNetlifyForm = async (formName, values) => {
  const encode = data => {
    const formData = new FormData()

    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }

    return formData
  }

  try {
    await fetch('/', {
      method: 'post',
      body: encode({ 'form-name': formName, ...values }),
    })

    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
