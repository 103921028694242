import React from 'react'

import { isInternal } from 'src/helpers'
import { StyledA, StyledGatsbyLink } from './Link.styled'

if (typeof window !== 'undefined') window.__navigatingToLink = false

export const Link = ({
  to,
  getProps,
  onClick = () => {},
  children,
  isActive: _isActive,
  ...props
}) =>
  to && isInternal(to) ? (
    <StyledGatsbyLink
      to={to}
      onClick={(...args) => {
        onClick(...args)
        window.__navigatingToLink = true
      }}
      getProps={getProps}
      {...props}
    >
      {children}
    </StyledGatsbyLink>
  ) : (
    <StyledA href={to} onClick={onClick} {...props}>
      {children}
    </StyledA>
  )
