import styled from 'react-emotion'
import { Link as GatsbyLink } from 'gatsby'

export const StyledA = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

export const StyledGatsbyLink = StyledA.withComponent(GatsbyLink)
