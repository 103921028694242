import { mapValues } from 'lodash'
import { ms, ssv } from 'src/helpers'

const theme = {
  colors: {
    black: '#000000',
    gray: '#9F9B90',
    darkGray: '#4a4a4a',
    lightGray: '#f2f2f2',
    green: '#006646',
    lightGreen: '#53C197',
    lightCream: '#FEFCF6',
    pink: '#F8E4DE',
    darkPink: '#cdb4ad',
    buttonPink: '#e15e70',
    transparentBlack: 'rgba(0, 0, 0, 0.3)',
    transparentWhite: 'rgba(255, 255, 255, 0.3)',
    white: '#FFFFFF',
  },
  lineHeights: {
    solid: 1,
    title: 1.15,
    copy: 1.5,
    wide: 1.75,
  },
  fontFamilies: {
    sansHeadline: '"Rubik", sans-serif',
    monoBody: '"IBM Plex Mono", mono',
    script: '"Quickbrush", script',
  },
  fontSize: s => ms(s / 2) + 'rem',
  mediaSizes: {
    t: '20rem',
    ts: '25rem',
    s: '30rem',
    m: '48rem',
    l: '60rem',
    x: '78rem',
    xx: '90rem',
  },
  radius: ssv(s => (typeof s === 'number' ? ms(s / 2) + 'rem' : s)),
  spacing: ssv(s => (typeof s === 'number' ? ms(s) + 'rem' : s)),
  transition: '150ms',
}

theme.mediaQueries = mapValues(
  theme.mediaSizes,
  mediaSize => `@media (min-width: ${mediaSize})`
)

// Shortcuts
theme.c = theme.colors
theme.f = theme.fontSize
theme.ff = theme.fontFamilies
theme.lh = theme.lineHeights
theme.mq = theme.mediaQueries
theme.ms = theme.mediaSizes
theme.r = theme.radius
theme.s = theme.spacing
theme.t = theme.transition

export default theme
